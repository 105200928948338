/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import {
  ENV_APPLICATION_ID,
  ENV_PROJECT_HANDLE,
  MULTITENANCY,
  SERVERLESS,
} from 'Env';
import React from 'react';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Row, Button } from 'reactstrap';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
import ReactExport from 'react-data-export';

const navbar = JSON.parse(localStorage.getItem('accessNav'));
const permision = JSON.parse(localStorage.getItem('permision'));

export const NAVBAR = navbar;
export const PERMISION = permision;

const url = 'https://api-serverless.iotera.io/';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let projectHandle = '';
let applicationId = '';
let refundPeriod = 2;
export const version = '2.2.6';
const baseUrl = window.location.href;
const split_baseUrl = baseUrl.split('//');
const url_sub = split_baseUrl[1];
const split_url_sub = url_sub.split('/');
const urlBase = split_url_sub[0];
if (localStorage.getItem('version') == undefined) {
  localStorage.setItem('version', version);
  localStorage.removeItem('authUser');
  localStorage.removeItem('role');
  localStorage.removeItem('email');
  localStorage.removeItem('allBp');
  localStorage.removeItem('permision');
  localStorage.removeItem('accessBP');
  localStorage.removeItem('accessNav');
  window.location.replace('https://' + urlBase + '/login');
} else {
  if (version != localStorage.getItem('version')) {
    // TODO Logout
    localStorage.removeItem('authUser');
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    localStorage.removeItem('allBp');
    localStorage.removeItem('permision');
    localStorage.removeItem('accessBP');
    localStorage.removeItem('accessNav');
    window.location.replace('https://' + urlBase + '/login');

    localStorage.setItem('version', version);
  }
}
/* Special handler for Nestle Project */
if (ENV_APPLICATION_ID == '1000000159' || ENV_APPLICATION_ID == '1000000211') {
  const nestleProjectHandle = localStorage.getItem('PROJECT');
  const nestleApplicationId = localStorage.getItem('APP_ID');

  if (nestleProjectHandle) {
    projectHandle = nestleProjectHandle;
    applicationId = nestleApplicationId;
    // refundPeriod = 3;
  } else {
    projectHandle = ENV_PROJECT_HANDLE;
    applicationId = ENV_APPLICATION_ID;
  }
  refundPeriod = 3;
} else {
  projectHandle = ENV_PROJECT_HANDLE;
  applicationId = ENV_APPLICATION_ID;
}
export const MULTITENANCY_HANDLE = MULTITENANCY;
export const PLATFORM_URL = 'https://api-serverless.iotera.io/';
export const PAGE_SIZE = 25;
let firebase_url = '';
let nameProject = 'Iotera';
if (MULTITENANCY) {
  firebase_url =
    'https://asia-southeast2-iotera-vending-multitenancy.cloudfunctions.net';
  projectHandle = localStorage.getItem('project_handle');
  nameProject = localStorage.getItem('project_handle');
} else {
  if (applicationId == '1000000209') {
    firebase_url = 'https://asia-southeast2-iotera-redboks.cloudfunctions.net';
    projectHandle = 'Redboks';
    nameProject = 'Redboks';
  } else if (applicationId == '1000000159') {
    firebase_url =
      'https://asia-southeast2-sv-nestle-milano.cloudfunctions.net';
    projectHandle = 'Nestle';
    nameProject = 'Nestle Milano';
  } else if (applicationId == '1000000211') {
    firebase_url = 'https://asia-southeast2-iotera-nestle.cloudfunctions.net';
    projectHandle = 'Franke';
    nameProject = 'Nestle Franke';
  } else if (applicationId == '1000000232') {
    firebase_url =
      'https://asia-southeast2-sv-hamparan-f48a1.cloudfunctions.net';
    projectHandle = 'Hamparan';
    nameProject = 'Hamparan Mas Mitra Niaga';
  } else if (applicationId == '1000000233') {
    firebase_url =
      'https://asia-southeast2-sv-vendkiosk-ttm.cloudfunctions.net';
    projectHandle = 'TTM';
    nameProject = 'Vendkiosk TTM';
  } else if (applicationId == '1000000240') {
    firebase_url =
      'https://asia-southeast2-sv-mandarin-sentosa.cloudfunctions.net';
    projectHandle = 'Mandarin';
    nameProject = 'Mandarin Sentosa';
  } else if (applicationId == 'Admin') {
    firebase_url = 'https://asia-southeast2-iotera-vending.cloudfunctions.net';
    projectHandle = 'Admin';
    nameProject = 'Iotera Admin';
  } else if (applicationId == '1000000226') {
    firebase_url = 'https://asia-southeast2-iotera1-vending.cloudfunctions.net';
    projectHandle = 'Indesso';
    nameProject = 'Indesso';
  } else if (applicationId == '1000000229') {
    firebase_url = 'https://asia-southeast2-sv-vendkiosk.cloudfunctions.net';
    projectHandle = 'Fuji';
    nameProject = 'Vendkiosk';
  } else if (applicationId == '1000000246') {
    firebase_url = 'https://asia-southeast2-sv-freshtin.cloudfunctions.net';
    projectHandle = 'Freshtin';
    nameProject = 'Freshtin';
  } else if (applicationId == '1000000250') {
    firebase_url = 'https://asia-southeast2-sv-wondermart.cloudfunctions.net';
    projectHandle = 'Wondermart';
    nameProject = 'Wondermart';
  } else if (applicationId == '1000000251') {
    firebase_url = 'https://asia-southeast2-sv-vendbox.cloudfunctions.net';
    projectHandle = 'Vendbox';
    nameProject = 'Vendbox';
  } else if (applicationId == '1000000253') {
    projectHandle = 'Bemart';
    firebase_url = 'https://asia-southeast2-sv-bemart.cloudfunctions.net';
    nameProject = 'Bemart';
  } else if (applicationId == '1000000254') {
    projectHandle = 'Monstermart';
    firebase_url = 'https://asia-southeast2-sv-monstermart.cloudfunctions.net';
    nameProject = 'Monstermart';
  } else if (applicationId == '1000000245') {
    projectHandle = 'Wapo';
    nameProject = 'Wahana Pronatural';

    firebase_url =
      'https://asia-southeast2-sv-hamparan-inaco.cloudfunctions.net';
  } else if (applicationId == '1000000258') {
    projectHandle = 'PerfectHealth';
    nameProject = 'Perfect Relax';

    firebase_url =
      'https://asia-southeast2-sv-perfect-health.cloudfunctions.net';
  } else if (applicationId == '1000000260') {
    projectHandle = 'DSN';
    nameProject = 'DSN';

    firebase_url = 'https://asia-southeast2-sv-dsn.cloudfunctions.net';
  } else if (applicationId == '1000000261') {
    projectHandle = 'BemartNs';
    firebase_url =
      'https://asia-southeast2-sv-bemart-nipponsekai.cloudfunctions.net';
  } else if (applicationId == '1000000263') {
    projectHandle = 'Jogjawa';
    nameProject = 'Jogjawa Supati';

    firebase_url =
      'https://asia-southeast2-sv-jogjawasupati.cloudfunctions.net';
  } else {
    projectHandle = 'Demo';
    firebase_url = 'https://asia-southeast2-iotera-vending.cloudfunctions.net';
  }
}
export const NAME_PROJECT = nameProject;
export const FIREBASE_URL = firebase_url;
export const APPLICATION_ID = applicationId;
export const REFUND_PERIOD = refundPeriod;
export const PROJECT_HANDLE = projectHandle;

/* Sharing profit multiplier */
let sharingProfitPercentage = 0;
if (
  applicationId == '1000000021' ||
  applicationId == '1000000229' ||
  applicationId == '1000000240'
) {
  sharingProfitPercentage = 0.075;
} else if (applicationId == '1000000209') {
  sharingProfitPercentage = 0.05;
}
export function buildWsParamAdmin(endPoint, app_id, body, timeout) {
  if (app_id == 'Admin') {
    app_id = '1000000021';
  }

  if (SERVERLESS) {
    const param = {
      url: url + app_id + endPoint,
    };
    if (body) {
      safeDeepSet(param, 'body', body);
    }
    if (timeout) {
      safeDeepSet(param, 'timeout', timeout);
    }
    return param;
  } else {
    const param = {
      headers: {
        'Vending-Application-Id': app_id,
      },
      url: 'https://app-smartvending.iotera.io' + endPoint,
    };
    if (body) {
      safeDeepSet(param, 'body', body);
    }
    if (timeout) {
      safeDeepSet(param, 'timeout', timeout);
    }
    return param;
  }
}
export function buildWsParam(endPoint, body, timeout) {
  if (SERVERLESS) {
    const param = {
      url: url + APPLICATION_ID + endPoint,
    };
    if (body) {
      safeDeepSet(param, 'body', body);
    }
    if (timeout) {
      safeDeepSet(param, 'timeout', timeout);
    }
    return param;
  } else {
    const param = {
      headers: {
        'Vending-Application-Id':
          APPLICATION_ID == 'Admin' ? '1000000021' : APPLICATION_ID,
      },
      url: 'https://app-smartvending.iotera.io' + endPoint,
    };
    if (body) {
      safeDeepSet(param, 'body', body);
    }
    if (timeout) {
      safeDeepSet(param, 'timeout', timeout);
    }
    return param;
  }
}
export const SHARING_PROFIT_PERCENTAGE = sharingProfitPercentage;
export function buildTitle(page) {
  if (MULTITENANCY) {
    return `${page} | ${projectHandle} Vending`;
  } else if (APPLICATION_ID == '1000000021') {
    return `${page} | Iotera Vending`;
  } else if (APPLICATION_ID == '1000000209') {
    return `${page} | Redboks`;
  } else if (APPLICATION_ID == '1000000159') {
    return `${page} | Nestle`;
  } else if (APPLICATION_ID == '1000000211') {
    return `${page} | Franke`;
  } else if (APPLICATION_ID == '1000000226') {
    return `${page} | Indesso`;
  } else if (APPLICATION_ID == '1000000229') {
    return `${page} | VendKiosk`;
  } else if (APPLICATION_ID == '1000000232') {
    return `${page} | Hamparan`;
  } else if (APPLICATION_ID == '1000000233') {
    return `${page} | Vendkiosk TTM`;
  } else if (APPLICATION_ID == '1000000240') {
    return `${page} | Mandarin`;
  } else if (APPLICATION_ID == '1000000245') {
    return `${page} | Wahana Pronatural`;
  } else if (APPLICATION_ID == '1000000246') {
    return `${page} | Freshtin`;
  } else if (APPLICATION_ID == '1000000250') {
    return `${page} | Wondermart`;
  } else if (APPLICATION_ID == '1000000251') {
    return `${page} | Vendbox`;
  } else if (APPLICATION_ID == '1000000253') {
    return `${page} | Bemart`;
  } else if (APPLICATION_ID == '1000000254') {
    return `${page} | Monstermart`;
  } else if (APPLICATION_ID == '1000000258') {
    return `${page} | Perfect Relax`;
  } else if (APPLICATION_ID == '1000000260') {
    return `${page} | DSN`;
  } else if (APPLICATION_ID == '1000000261') {
    return `${page} | Bemart Nippon Sekai`;
  } else if (APPLICATION_ID == '1000000263') {
    return `${page} | Jogjawa Supati`;
  } else if (APPLICATION_ID == '1000000265') {
    return `${page} | Demo Multi`;
  } else if (APPLICATION_ID == '1000000266') {
    return `${page} | Cafetaria Udinus`;
  } else if (APPLICATION_ID == '1000000267') {
    return `${page} | Auvo`;
  }
}
export function getAvatarTheme() {
  if (APPLICATION_ID == '1000000226') {
    return `avatar-title-franke`;
  } else if (APPLICATION_ID == '1000000232') {
    return `avatar-title-hamparan`;
  } else {
    return `avatar-title`;
  }
}
export function getButtonTheme() {
  if (APPLICATION_ID == '1000000226' || APPLICATION_ID == '1000000211') {
    return `font-16 btn-block btn btn-franke text-white`;
  } else if (APPLICATION_ID == '1000000232') {
    return 'font-16 btn-block btn btn-hamparan';
  } else if (APPLICATION_ID == '1000000159') {
    return 'font-16 btn-block btn btn-nestle text-white';
  } else {
    return 'font-16 btn-block btn btn-primary';
  }
}
export function getButtonNettTheme() {
  if (APPLICATION_ID == '1000000226' || APPLICATION_ID == '1000000211') {
    return `btn btn-franke white btn-sm`;
  } else if (APPLICATION_ID == '1000000232') {
    return 'btn btn-hamparan white btn-sm';
  } else if (APPLICATION_ID == '1000000159') {
    return 'btn btn-nestle btn-sm white';
  } else {
    return 'btn btn-primary btn-sm';
  }
}
export function timeConvert(n) {
  var num = n;
  var day = num / 60 / 24;
  var rday = Math.floor(day);
  var hours = (day - rday) * 24;
  var rhours = Math.round(hours);
  return rday + ' Days(s) and ' + rhours + ' hours(s) ';
}
export function handleTimeStamp(time) {
   if (time) {
    const date1 = new Date(time).toLocaleDateString('en-GB');
    const time1 = new Date(time).toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  }
}
export function handleValidTime(date) {
  const time1 = new Date(date).toLocaleTimeString('en-GB');
  return time1;
}
export function handleValidRefundDate(date) {
  const date1 = new Date(date).toLocaleDateString('en-GB');
  return date1;
}

export function handleValidDate(date) {
  if (date) {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  }
}
export function handleValidDateBp(date) {
  if (date == null) {
    return null;
  } else {
    const date1 = new Date(date).toLocaleDateString('en-GB');
    const time1 = new Date(date).toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  }
}
export function getTableTransaction(handleRefundedMidTrans, handleDetail) {
  const date = new Date();
  const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
  var one_day = 1000 * 60;
  if (APPLICATION_ID == '1000000211' || APPLICATION_ID == '1000000226') {
    return [
      {
        text: 'id',
        dataField: 'id',
        sort: true,
        hidden: true,
        formatter: (cellContent, row) => <>{row.id}</>,
      },
      {
        dataField: 'detail.order_id',
        text: 'Order',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <p
              style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
              className="mb-1"
              onClick={() => handleDetail(row)}
            >
              {safeDeepGet(row, ['detail', 'order_id'], '-')}
            </p>
            <p className="mb-1">{safeDeepGet(row, 'name', '-')}</p>
            <p className="mb-1">
              {handleValidDate(
                safeDeepGet(row, ['time', 'firestore_timestamp'], '-')
              )}
            </p>
            {/* <p className="mb-1">{row.name}</p> */}
          </>
        ),
      },
      {
        dataField: 'product.sku',
        text: 'Product',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <p style={{ fontWeight: 'bold' }} className="mb-1">
              {safeDeepGet(
                row,
                ['product', 'name'],
                safeDeepGet(row, ['product', 'sku'], '-')
              )}
            </p>

            <p>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
              'refunded' ? (
                <p style={{ marginTop: 10 }}>
                  {Number(safeDeepGet(row, ['payment', 'amount'], '0'))
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </p>
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
                'settlement' ? (
                <p style={{ color: 'green', marginTop: 15 }}>
                  {'+' +
                    Number(
                      safeDeepGet(
                        row,
                        ['payment', 'nett'],
                        safeDeepGet(row, ['payment', 'amount'], '0')
                      )
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </p>
              ) : (
                <p style={{ marginTop: 15 }}>
                  {Number(
                    safeDeepGet(
                      row,
                      ['payment', 'nett'],
                      safeDeepGet(row, ['payment', 'amount'], '0')
                    )
                  )
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </p>
              )}
            </p>
          </>
        ),
      },
      {
        dataField: 'Method',
        text: 'Payment Method',
        formatter: (cellContent, row) => (
          <>
            <p>{safeDeepGet(row, ['payment', 'method'], '-')}</p>
          </>
        ),
      },
      {
        dataField: 'paymentStatus',
        text: 'Payment / Dispense Status',
        formatter: (cellContent, row) => (
          <>
            <p>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'cancel' ? (
                <Tippy content="Dibatalkan oleh pembeli, atau timeout dari sisi vending mesin">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'failure' ? (
                <Tippy content="Kegagalan Komunikasi dengan bank">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'refunded' ? (
                <Tippy content="Dana sudah dikembalikan ke pembeli">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : (
                <Tippy
                  content={safeDeepGet(
                    row,
                    ['payment', 'detail', 'issuer'],
                    '-'
                  )}
                >
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              )}
            </p>
            <p>
              <button
                style={{ border: 'none' }}
                className={
                  'font-size-12 badge-soft-' + safeDeepGet(row, 'badge', 'info')
                }
                color={safeDeepGet(row, 'badge', 'info')}
              >
                {safeDeepGet(row, ['detail', 'dispense_status'], '-')}
              </button>
            </p>
          </>
        ),
      },
    ];
  } else if (APPLICATION_ID == '1000000159') {
    return [
      {
        text: 'id',
        dataField: 'id',
        sort: true,
        hidden: true,
        formatter: (cellContent, row) => <>{row.id}</>,
      },
      {
        dataField: 'detail.order_id',
        text: 'Order',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <p
              style={{
                fontWeight: 'bold',
                textDecorationLine: 'underline',
                color: 'black',
              }}
              className="mb-1"
              onClick={() => handleDetail(row)}
            >
              {safeDeepGet(row, ['detail', 'order_id'])}
            </p>
            <p className="mb-0"></p>
            <p className="mb-1">
              {handleValidDate(row.time.firestore_timestamp)}
            </p>
            {/* <p className="mb-1">{row.name}</p> */}
          </>
        ),
      },
      {
        dataField: 'name',
        text: 'Business Point',
        sort: true,
      },
      {
        dataField: 'product.sku',
        text: 'Product',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <Tippy content={row.product.sku}>
              <button
                style={{ border: 'none' }}
                className={'font-size-12 btn btn-outline-light'}
              >
                {row.nameProduct}
              </button>
            </Tippy>
          </>
        ),
      },
      {
        dataField: 'total',
        text: 'Total',
        formatter: (cellContent, row) => (
          <>
            {safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
            'refunded' ? (
              <p style={{ marginTop: 10, textAlign: 'right' }}>
                {Number(safeDeepGet(row, ['payment', 'amount'], '0'))
                  .toFixed(2)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
              </p>
            ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
              'settlement' ? (
              <p style={{ color: 'green', marginTop: 15, textAlign: 'right' }}>
                {'+' +
                  Number(
                    safeDeepGet(
                      row,
                      ['payment', 'nett'],
                      safeDeepGet(row, ['payment', 'amount'], '0')
                    )
                  )
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
              </p>
            ) : (
              <p style={{ marginTop: 15, textAlign: 'right' }}>
                {Number(
                  safeDeepGet(
                    row,
                    ['payment', 'nett'],
                    safeDeepGet(row, ['payment', 'amount'], '0')
                  )
                )
                  .toFixed(2)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
              </p>
            )}
          </>
        ),
      },
      {
        dataField: 'paymentStatus',
        text: 'Payment Status',
        formatter: (cellContent, row) => (
          <>
            {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
            'cancel' ? (
              <Tippy content="Dibatalkan oleh pembeli, atau timeout dari sisi vending mesin">
                <span
                  style={{ border: 'none' }}
                  className={
                    'font-size-12 badge badge-pill badge-soft-' +
                    safeDeepGet(row, 'badgeclass', 'info')
                  }
                  color={safeDeepGet(row, 'badgeclass', 'info')}
                >
                  {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                </span>
              </Tippy>
            ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'failure' ? (
              <Tippy content="Kegagalan Komunikasi dengan bank">
                <span
                  style={{ border: 'none' }}
                  className={
                    'font-size-12 badge badge-pill badge-soft-' +
                    safeDeepGet(row, 'badgeclass', 'info')
                  }
                  color={safeDeepGet(row, 'badgeclass', 'info')}
                >
                  {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                </span>
              </Tippy>
            ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'refunded' ? (
              <Tippy content="Dana sudah dikembalikan ke pembeli">
                <span
                  style={{ border: 'none' }}
                  className={
                    'font-size-12 badge badge-pill badge-soft-' +
                    safeDeepGet(row, 'badgeclass', 'info')
                  }
                  color={safeDeepGet(row, 'badgeclass', 'info')}
                >
                  {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                </span>
              </Tippy>
            ) : (
              <Tippy content={safeDeepGet(row, ['payment', 'method'], '-')}>
                <span
                  style={{ border: 'none' }}
                  className={
                    'font-size-12 badge badge-pill badge-soft-' +
                    safeDeepGet(row, 'badgeclass', 'info')
                  }
                  color={safeDeepGet(row, 'badgeclass', 'info')}
                >
                  {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                </span>
              </Tippy>
            )}
          </>
        ),
      },
      {
        dataField: 'dispensestatus',
        text: 'Dispense Status',
        formatter: (cellContent, row) => (
          <button
            style={{ border: 'none', width: '100px', height: '50px' }}
            className={
              'font-size-12 badge-soft-' + safeDeepGet(row, 'badge', 'info')
            }
            color={safeDeepGet(row, 'badge', 'info')}
          >
            <p className="text-warp">
              {safeDeepGet(row, ['detail', 'dispense_status'], '-')}
            </p>
          </button>
        ),
      },
      {
        dataField: 'refund',
        isDummyField: true,
        text: 'Refund',
        sort: true,
        hidden: true,
        formatter: (cellContent, row) => (
          <>
            {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
            'settlement' ? (
              row.time.timestamp >= twoDay ? (
                <Tippy
                  content={timeConvert(
                    Math.ceil((row.time.timestamp - twoDay) / one_day)
                  )}
                >
                  <button
                    type="button"
                    color="success"
                    className="btn btn-sm btn-rounded btn-success"
                    onClick={() => handleRefundedMidTrans(row)}
                  >
                    Refund
                  </button>
                </Tippy>
              ) : (
                ' '
              )
            ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'refunded' ? (
              <>
                <p className="mb-0">
                  {handleValidRefundDate(row.detail.refund_time)}
                </p>
                <p className="mb-1">
                  {handleValidTime(row.detail.refund_time)}
                </p>
              </>
            ) : (
              ' '
            )}
          </>
        ),
      },
    ];
  } else {
    return [
      {
        text: 'id',
        dataField: 'id',
        sort: true,
        hidden: true,
        formatter: (cellContent, row) => <>{row.id}</>,
      },
      {
        dataField: 'detail.order_id',
        text: 'Order',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <Link
              to={'/transaction/detail/' + safeDeepGet(row, 'id')}
              style={{
                color: 'black',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
              className="mb-2"
            >
              {safeDeepGet(row, ['detail', 'order_id'])}
            </Link>
            <p className="mb-0"></p>
            <p className="mb-1">
              {handleValidDate(row.time.firestore_timestamp)}
            </p>
          </>
        ),
      },
      {
        dataField: 'name',
        text: 'Business Point',
        sort: true,
      },
      {
        dataField: 'nameProduct',
        text: 'Product & Column',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <Tippy content={row.product.sku}>
              <button
                style={{ border: 'none', fontWeight: 'bold' }}
                className={'font-size-12 btn btn-outline-light'}
              >
                {row.nameProduct}
              </button>
            </Tippy>
            <p className="mt-2" style={{ marginLeft: '0.8rem' }}>
              {row.product.column}
            </p>
          </>
        ),
      },
      {
        dataField: 'total',
        text: 'Total',
        formatter: (cellContent, row) => (
          <>
            {safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
            'refunded' ? (
              <p style={{ color: 'red', marginTop: 10, textAlign: 'right' }}>
                {'-' +
                  Number(
                    safeDeepGet(
                      row,
                      ['detail', 'refund_amount'],
                      safeDeepGet(row, ['payment', 'amount'], '0')
                    )
                  )
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
              </p>
            ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
                'settlement' ||
              safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
                'outstanding' ? (
              <p style={{ color: 'green', marginTop: 15, textAlign: 'right' }}>
                {safeDeepGet(navbar, ['Transaction', 'landlord'], 'off') ===
                'on'
                  ? '+' +
                    Number(
                      safeDeepGet(
                        row,
                        ['payment', 'amount'],
                        safeDeepGet(row, ['payment', 'amount'], '0')
                      )
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                  : '+' +
                    Number(
                      safeDeepGet(
                        row,
                        ['payment', 'nett'],
                        safeDeepGet(row, ['payment', 'amount'], '0')
                      )
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
              </p>
            ) : (
              <p style={{ marginTop: 15, textAlign: 'right' }}>
                {Number(
                  safeDeepGet(
                    row,
                    ['payment', 'nett'],
                    safeDeepGet(row, ['payment', 'amount'], '0')
                  )
                )
                  .toFixed(2)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
              </p>
            )}
          </>
        ),
      },
      {
        dataField: 'payment.method',
        text: 'Payment Status',
        formatter: (cellContent, row) => (
          <>
            <Row>
              <p
                style={{ border: 'none', fontWeight: 'bold' }}
                className={'font-size-12 btn btn-outline-light'}
              >
                {safeDeepGet(row, ['payment', 'method'], '-')}
              </p>
            </Row>
            <Row>
              <span
                style={{ border: 'none' }}
                className={
                  'font-size-12 badge badge-pill badge-soft-' +
                  safeDeepGet(row, 'badgeclass', 'info')
                }
                color={safeDeepGet(row, 'badgeclass', 'info')}
              >
                {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
              </span>
            </Row>
          </>
        ),
      },
      {
        dataField: 'detail.dispense_status',
        text: 'Dispense Status',
        formatter: (cellContent, row) => (
          <button
            style={{ border: 'none', width: '100px' }}
            className={
              'font-size-12 badge-soft-' + safeDeepGet(row, 'badge', 'info')
            }
            color={safeDeepGet(row, 'badge', 'info')}
          >
            <p
              style={{ whiteSpace: 'normal', marginBottom: '0px' }}
              className="text-center"
            >
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'cancel'
                ? '-'
                : safeDeepGet(row, ['detail', 'dispense_status'], '-')}
            </p>
          </button>
        ),
      },
      {
        dataField: 'refund',
        isDummyField: true,
        text: 'Refund',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
            'settlement' ? (
              row.time.timestamp >= twoDay ? (
                safeDeepGet(row, ['payment', 'method'], '-') ==
                  'QRIS-MIDTRANS' ||
                safeDeepGet(row, ['payment', 'method'], '-') ==
                  'QRIS-BANKNOBU' ||
                safeDeepGet(row, ['payment', 'method'], '-') ==
                  'QRIS-MIDTRANS-PARTNER' ? (
                  <Tippy
                    content={timeConvert(
                      Math.ceil((row.time.timestamp - twoDay) / one_day)
                    )}
                  >
                    <button
                      type="button"
                      color="success"
                      className="btn btn-sm btn-rounded btn-success"
                      onClick={() => handleRefundedMidTrans(row)}
                    >
                      Refund
                    </button>
                  </Tippy>
                ) : safeDeepGet(row, ['payment', 'method'], '-') == 'CASH' ? (
                  <Tippy
                    content={timeConvert(
                      Math.ceil((row.time.timestamp - twoDay) / one_day)
                    )}
                  >
                    <button
                      type="button"
                      color="success"
                      className="btn btn-sm btn-rounded btn-success"
                      onClick={() => handleRefundedMidTrans(row)}
                    >
                      Refund
                    </button>
                  </Tippy>
                ) : null
              ) : (
                ' '
              )
            ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'refunded' ? (
              <>
                <p className="mb-0">
                  {handleValidRefundDate(row.detail.refund_time)}
                </p>
                <p className="mb-1">
                  {handleValidTime(row.detail.refund_time)}
                </p>
              </>
            ) : (
              ' '
            )}
          </>
        ),
      },
    ];
  }
}
export function getTableBusinessPoint(
  role,
  isMobile,
  handleStockClick,
  handlePlanogramClick,
  handleInfoClick,
  handleCalibrationClick,
  handleAlertClick,
  handleDeleteClick,
  handleAccountClick,
  handleClearCashConfirm,
  handleOptionsClick,
  handleResetFlowConfirm
) {
  return [
    {
      text: 'id',
      dataField: 'id',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'name',
      text: 'Business Point',
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {APPLICATION_ID == '1000000159' ? (
            <>
              <p
                style={{ fontWeight: 'bold', fontSize: '11px' }}
                className="mb-1  text-wrap"
              >
                {row.name}
              </p>
              <p className="mb-1">{row.id}</p>
            </>
          ) : (
            <>
              <p
                style={{ fontWeight: 'bold', fontSize: '11px' }}
                className="mb-1 text-wrap"
              >
                {row.name}
              </p>
              <p className="mb-1">{row.vmcode}</p>
            </>
          )}
        </>
      ),
    },
    {
      dataField: 'serial_number',
      text: 'VN SN',
      sort: true,

      formatter: (cellContent, row) => (
        <>
          <p style={{ fontWeight: 'bold', fontSize: '11px' }} className="mb-1">
            {row.serial_number}
          </p>
          <p style={{ fontSize: '11px' }} className="mb-1">
            {row.sn}
          </p>
          <p style={{ fontSize: '11px' }} className="mb-0">
            {row.joiningDate}
          </p>
        </>
      ),
    },
    {
      dataField: 'type',
      text: 'Type',
      formatter: (cellContent, row) => (
        <p style={{ fontSize: '11px' }}>
          {safeDeepGet(row, 'type', 'none').charAt(0).toUpperCase() +
            safeDeepGet(row, 'type', 'none').substr(1)}
        </p>
      ),
    },
    {
      dataField: 'address',
      text: 'Address',
      formatter: (cellContent, row) => (
        <p style={{ fontSize: '11px' }} className="text-wrap">
          {row.address}
        </p>
      ),
    },
    {
      dataField: 'online',
      text: 'VM Status',
      formatter: (cellContent, row) => (
        <Tippy
          maxWidth={250}
          content={
            ' Last Online ' +
            handleValidDateBp(row.last_online) +
            ' \n \n \r Last Offline ' +
            handleValidDateBp(row.last_offline) +
            ''
          }
        >
          <button
            type="button"
            style={{ borderRadius: '23px', fontSize: '12' }}
            className={'btn  btn-sm btn-' + (row.online ? 'success' : 'danger')}
          >
            {row.online ? 'online' : 'offline'}
          </button>
        </Tippy>
      ),
    },
    {
      dataField: 'cp',
      text: 'Contact Person',
      formatter: (cellContent, row) => (
        <>
          {APPLICATION_ID == '1000000159' ? (
            <>
              <p style={{ fontWeight: 'bold' }} className="mb-1">
                {row.pic}
              </p>
              <p style={{ fontSize: '11px' }} className="mb-1">
                {row.cp}
              </p>
            </>
          ) : (
            <>
              <p style={{ fontSize: '11px' }} className="mb-1">
                {row.cp}
              </p>
              <p style={{ fontSize: '11px' }} className="mb-0">
                {row.email}
              </p>
            </>
          )}
        </>
      ),
    },
    {
      dataField: 'action',
      isDummyField: true,
      editable: false,
      text: 'Action',
      formatter: (cellContent, row) => (
        <>
          {APPLICATION_ID == '1000000159' ? (
            role == 'refiller' ? (
              <div className="d-flex gap-2">
                <Tippy content={'Stock'}>
                  <Link className="text-info" to="#">
                    <i
                      className="mdi mdi-tray-full font-size-14"
                      id="menutooltip"
                      onClick={() => handleStockClick(row)}
                    ></i>
                  </Link>
                </Tippy>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <Tippy content={'Stock'}>
                  <Link className="text-info" to="#">
                    <i
                      className="mdi mdi-tray-full font-size-14"
                      id="menutooltip"
                      onClick={() => handleStockClick(row)}
                    ></i>
                  </Link>
                </Tippy>
                <Tippy content={'Planogram'}>
                  <Link className="text-info" to="#">
                    <i
                      className="mdi mdi-view-list-outline
                    font-size-15"
                      id="menutooltip"
                      onClick={() => handlePlanogramClick(row)}
                    ></i>
                  </Link>
                </Tippy>

                <Tippy content={'Info'}>
                  <Link className="text-success" data-tip={'Hello'} to="#">
                    <i
                      className="mdi mdi-file-cog-outline font-size-15"
                      id="edittooltip"
                      onClick={() => handleInfoClick(row)}
                    ></i>
                  </Link>
                </Tippy>

                <Tippy content={'Options'}>
                  <Link className="text-success" to="#">
                    <i
                      className="mdi mdi-cellphone-cog font-size-15"
                      id="menutooltip"
                      onClick={() => handleCalibrationClick(row)}
                    ></i>
                  </Link>
                </Tippy>
                <Tippy content={'Alert'}>
                  <Link className="text-warning" to="#">
                    <i
                      className="mdi mdi-alert-circle font-size-15"
                      id="edittooltip"
                      onClick={() => handleAlertClick(row)}
                    ></i>
                  </Link>
                </Tippy>
                <Tippy content={'Delete'}>
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-delete font-size-15"
                      id="deletetooltip"
                      // onClick={() => handleDeleteClick(row.id)}
                      onClick={() => handleDeleteClick(row)}
                    ></i>
                  </Link>
                </Tippy>
              </div>
            )
          ) : APPLICATION_ID == '1000000211' ||
            APPLICATION_ID == '1000000260' ? (
            role == 'refiller' ? (
              <div className="d-flex gap-2">
                <Tippy content={'Stock'}>
                  <Link className="text-info" to="#">
                    <i
                      className="mdi mdi-tray-full font-size-15"
                      id="menutooltip"
                      onClick={() => handleStockClick(row)}
                    ></i>
                  </Link>
                </Tippy>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <Tippy content={'Planogram'}>
                  <Link className="text-info" to="#">
                    <i
                      className="mdi mdi-view-list-outline
                    font-size-15"
                      id="menutooltip"
                      onClick={() => handlePlanogramClick(row)}
                    ></i>
                  </Link>
                </Tippy>
                {APPLICATION_ID == '1000000226' ? (
                  ' '
                ) : (
                  <Tippy content={'RFID'}>
                    <Link className="text-info" to="#">
                      <i
                        className="mdi mdi-account
                    font-size-15"
                        id="menutooltip"
                        onClick={() => handleAccountClick(row)}
                      ></i>
                    </Link>
                  </Tippy>
                )}

                <Tippy content={'Info'}>
                  <Link className="text-success" data-tip={'Hello'} to="#">
                    <i
                      className="mdi mdi-file-cog-outline font-size-15"
                      id="edittooltip"
                      onClick={() => handleInfoClick(row)}
                    ></i>
                  </Link>
                </Tippy>

                <Tippy content={'Options'}>
                  <Link className="text-success" to="#">
                    <i
                      className="mdi mdi-cellphone-cog font-size-15"
                      id="menutooltip"
                      onClick={() => handleCalibrationClick(row)}
                    ></i>
                  </Link>
                </Tippy>
                <Tippy content={'Delete'}>
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-delete font-size-15"
                      id="deletetooltip"
                      // onClick={() => handleDeleteClick(row.id)}
                      onClick={() => handleDeleteClick(row)}
                    ></i>
                  </Link>
                </Tippy>
              </div>
            )
          ) : APPLICATION_ID == '1000000226' ? (
            role == 'refiller' ? (
              <div className="d-flex gap-2">
                <Tippy content={'Stock'}>
                  <Link className="text-info" to="#">
                    <i
                      className="mdi mdi-tray-full font-size-15"
                      id="menutooltip"
                      onClick={() => handleStockClick(row)}
                    ></i>
                  </Link>
                </Tippy>
              </div>
            ) : role == 'landlord' ? (
              <div className="d-flex gap-2"></div>
            ) : role == 'finance' ? (
              <div className="d-flex gap-2"></div>
            ) : (
              <div className="d-flex gap-2">
                <Tippy content={'Planogram'}>
                  <Link className="text-info" to="#">
                    <i
                      className="mdi mdi-view-list-outline
              font-size-15"
                      id="menutooltip"
                      onClick={() => handlePlanogramClick(row)}
                    ></i>
                  </Link>
                </Tippy>
                <Tippy content={'Info'}>
                  <Link className="text-success" data-tip={'Hello'} to="#">
                    <i
                      className="mdi mdi-file-cog-outline font-size-15"
                      id="edittooltip"
                      onClick={() => handleInfoClick(row)}
                    ></i>
                  </Link>
                </Tippy>

                <Tippy content={'Options'}>
                  <Link className="text-success" to="#">
                    <i
                      className="mdi mdi-cellphone-cog font-size-15"
                      id="menutooltip"
                      onClick={() => handleCalibrationClick(row)}
                    ></i>
                  </Link>
                </Tippy>
                <Tippy content={'Delete'}>
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-delete font-size-15"
                      id="deletetooltip"
                      // onClick={() => handleDeleteClick(row.id)}
                      onClick={() => handleDeleteClick(row)}
                    ></i>
                  </Link>
                </Tippy>
              </div>
            )
          ) : APPLICATION_ID == '1000000232' ? (
            <div className="d-flex gap-2">
              <Tippy content={'Info'}>
                <Link
                  className="text-success"
                  to={`/business-point/info/${row.device_id}`}
                >
                  <i
                    className="mdi mdi-pencil font-size-15"
                    id="edittooltip"
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Planogram'}>
                <Link
                  className="text-info"
                  to={`/business-point/planogram/${row.device_id}`}
                >
                  <i className="mdi mdi-menu font-size-15" id="menutooltip"></i>
                </Link>
              </Tippy>
              <Tippy content={'RFID'}>
                <Link className="text-info" to="#">
                  <i
                    className="mdi mdi-account
            font-size-15"
                    id="menutooltip"
                    onClick={() => handleAccountClick(row)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Delete'}>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-delete font-size-15"
                    id="deletetooltip"
                    // onClick={() => handleDeleteClick(row.device_id)}
                    onClick={() => handleDeleteClick(row)}
                  ></i>
                </Link>
              </Tippy>
            </div>
          ) : isMobile ? (
            <div className="d-flex gap-2">
              <Link
                className="text-success"
                to={`/business-point/info/${row.device_id}`}
              >
                Edit
              </Link>
              <Link
                className="text-info"
                to={`/business-point/planogram/${row.type}/${row.device_id}`}
              >
                Planogram
              </Link>
              <Link className="text-info" to={`/alert/device/${row.device_id}`}>
                Alert
              </Link>
              <Link
                className="text-danger"
                to="#"
                onClick={() => handleDeleteClick(row)}
              >
                Delete
              </Link>
            </div>
          ) : (
            <div className="d-flex gap-2">
              <Tippy content={'Info'}>
                <Link
                  className="text-success"
                  to={`/business-point/info/${row.device_id}`}
                >
                  <i
                    className="mdi mdi-pencil font-size-15"
                    id="edittooltip"
                  ></i>
                </Link>
              </Tippy>
              {APPLICATION_ID == '1000000265' ||
              APPLICATION_ID == '1000000276' ? (
                <>
                  <Tippy content={'Fee'}>
                    <Link
                      className="text-success"
                      to={`/business-point/options/${row.device_id}`}
                    >
                      <i
                        className="mdi mdi-cash-multiple font-size-15"
                        id="edittooltip"
                      ></i>
                    </Link>
                  </Tippy>
                </>
              ) : (
                <></>
              )}

              <Tippy content={'Planogram'}>
                <Link
                  className="text-info"
                  to={`/business-point/planogram/${row.type}/${row.device_id}`}
                >
                  <i className="mdi mdi-menu font-size-15" id="menutooltip"></i>
                </Link>
              </Tippy>
              <Tippy content={'Alert'}>
                <Link
                  className="text-info"
                  to={`/alert/device/${row.device_id}`}
                >
                  <i
                    className="mdi mdi-alert-circle-outline font-size-15"
                    id="edittooltip"
                  ></i>
                </Link>
              </Tippy>
              {row.type == 'water_dispenser' ? (
                <>
                  <Tippy content={'Reset FlowMeter'}>
                    <Link
                      className="text-danger"
                      to="#"
                      // to={`/alert/device/${row.device_id}`}
                    >
                      <i
                        className="mdi mdi-restart font-size-15"
                        id="cashremovetooltip"
                        onClick={() => handleResetFlowConfirm(row)}
                      ></i>
                    </Link>
                  </Tippy>
                </>
              ) : (
                <></>
              )}
              <Tippy content={'Clear Cash'}>
                <Link
                  className="text-danger"
                  to="#"
                  // to={`/alert/device/${row.device_id}`}
                >
                  <i
                    className="mdi mdi-cash-remove  font-size-15"
                    id="cashremovetooltip"
                    onClick={() => handleClearCashConfirm(row)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Delete'}>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-delete font-size-15"
                    id="deletetooltip"
                    onClick={() => handleDeleteClick(row)}
                  ></i>
                </Link>
              </Tippy>
            </div>
          )}
        </>
      ),
    },
  ];
}

export function getTableAccount(handleEditClick, handleDeleteClick) {
  return [
    {
      text: 'id',
      dataField: 'id',
      sort: true,
      hidden: true,
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: 'Name',
      dataField: 'name',
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <h5 className="font-size-14 mb-1">{row.name}</h5>
        </>
      ),
    },
    {
      dataField: 'role',
      text: 'Role',
      formatter: (cellContent, row) => (
        <>
          <p className="font-size-14 mb-1">{row.role}</p>
        </>
      ),
    },
    {
      dataField: 'action',
      isDummyField: true,
      editable: false,
      text: 'Action',
      formatter: (cellContent, Role) => (
        <div className="d-flex gap-3">
          <Tippy content={'Edit'}>
            <Link className="text-success" to="#">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => handleEditClick(Role)}
              ></i>
            </Link>
          </Tippy>
          <Tippy content={'Delete'}>
            <Link className="text-danger" to="#">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => handleDeleteClick(Role)}
              ></i>
            </Link>
          </Tippy>
        </div>
      ),
    },
  ];
}

export function handleHourtoSecond(duration) {
  var seconds = Math.floor((duration / 1000) % 60);
  var minutes = Math.floor((duration / (1000 * 60)) % 60);
  var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return hours + ' J ' + minutes + ' M ' + seconds + ' D ';
}
export function escapeHtml(str) {
  var div = document.createElement('div');
  div.innerHTML = str;
  return div.innerText;
}
export function getTableAlert(historical, historical_device, byDevice, id) {
  if (historical || historical_device) {
    return [
      {
        text: 'Key',
        dataField: 'key',
        sort: true,
        formatter: (cellContent, row) => (
          <Link
            to={'/alert/history/' + safeDeepGet(row, 'key')}
            style={{
              color: 'black',
              // fontWeight: 'bold',
              textDecoration: 'underline',
            }}
            className="mb-2"
          >
            {safeDeepGet(row, 'key')}
          </Link>
        ),
      },
      {
        text: 'Error_Code',
        dataField: 'error_code',
        sort: true,
        formatter: (cellContent, row) => <>{row.error_code}</>,
      },
      {
        text: 'Initial Time',
        dataField: 'initial_ts',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <p className=" mb-1">{handleTimeStamp(row.initial_ts)}</p>
          </>
        ),
      },
      {
        text: 'Latest Time',
        dataField: 'latest_ts',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <p className=" mb-1">{handleTimeStamp(row.latest_ts)}</p>
          </>
        ),
      },
      {
        dataField: 'active_for',
        sort: true,
        text: 'Active For',
        formatter: (cellContent, row) => (
          <>
            <p className=" mb-1">{handleHourtoSecond(row.active_for)}</p>
          </>
        ),
      },
      {
        dataField: 'value',
        text: 'Active',
        formatter: (cellContent, row) => (
          <>
            <p className="text-wrap font-size-14 mb-1">
              <button
                style={{ border: 'none' }}
                className={
                  'font-size-12 badge-soft-' +
                  (row.value == 'true' ? 'danger' : 'success')
                }
                color={row.value == 'true' ? 'danger' : 'success'}
              >
                {row.value == 'true' ? 'Active' : 'Solve'}
              </button>
            </p>
          </>
        ),
      },
    ];
  } else {
    return [
      {
        text: 'Business Point',
        dataField: 'device_name',
        sort: true,
        formatter: (cellContent, row) => (
          <p>
            {safeDeepGet(row, "device_name", id )}
          </p>
        )
      },
      {
        text: 'Key',
        dataField: 'key',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {safeDeepGet(row, 'key') != 'online' ? (
              safeDeepGet(row, 'key') != 'business_point' ? (
                safeDeepGet(row, 'key') != 'no_transaction' ? (
                  safeDeepGet(row, 'key') != 'machine' ? (
                    byDevice ? (
                      <Link
                        to={
                          '/alert/device-history/' +
                          id +
                          '/' +
                          safeDeepGet(row, 'key')
                        }
                        style={{
                          color: 'black',
                          // fontWeight: 'bold',
                          textDecoration: 'underline',
                        }}
                        className="mb-2"
                      >
                        {safeDeepGet(row, 'key')}
                      </Link>
                    ) : (
                      <Link
                        to={
                          '/alert/device-history/' +
                          row.device_id +
                          '/' +
                          safeDeepGet(row, 'key')
                        }
                        style={{
                          color: 'black',
                          // fontWeight: 'bold',
                          textDecoration: 'underline',
                        }}
                        className="mb-2"
                      >
                        {safeDeepGet(row, 'key')}
                      </Link>
                    )
                  ) : (
                    <p
                      style={{
                        color: 'black',
                        // fontWeight: 'bold',
                      }}
                    >
                      {safeDeepGet(row, 'key')}
                    </p>
                  )
                ) : (
                  <p
                    style={{
                      color: 'black',
                      // fontWeight: 'bold',
                    }}
                  >
                    {safeDeepGet(row, 'key')}
                  </p>
                )
              ) : (
                <p
                  style={{
                    color: 'black',
                    // fontWeight: 'bold',
                  }}
                >
                  {safeDeepGet(row, 'key')}
                </p>
              )
            ) : (
              <p
                style={{
                  color: 'black',
                  // fontWeight: 'bold',
                }}
              >
                {safeDeepGet(row, 'key')}
              </p>
            )}
          </>
        ),
      },
      {
        text: 'Initial Time',
        dataField: 'initial_ts',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <p className=" mb-1">{handleValidDateBp(row.initial_ts)}</p>
          </>
        ),
      },
      {
        dataField: 'notification',
        text: 'Notification',
        formatter: (cellContent, row) => (
          <>
            {safeDeepGet(row, 'key') != 'online' &&
            safeDeepGet(row, 'key') != 'business_point' &&
            safeDeepGet(row, 'key') != 'no_transaction' &&
            safeDeepGet(row, 'key') != 'machine' &&
            safeDeepGet(row, 'key') != '' ? (
              escapeHtml(safeDeepGet(row, 'desc', ''))
            ) : (
              <textarea
                id="notificationArea"
                cols={
                  safeDeepGet(
                    row,
                    'desc',
                    safeDeepGet(
                      row,
                      ['notification_open', 0, 'detail', 'content'],
                      ''
                    )
                  ) == ''
                    ? 55
                    : 55
                }
                rows={
                  safeDeepGet(
                    row,
                    'desc',
                    safeDeepGet(
                      row,
                      ['notification_open', 0, 'detail', 'content'],
                      ''
                    )
                  ) == ''
                    ? 4
                    : 14
                }
                disabled={true}
                value={
                  safeDeepGet(row, 'desc', '') == ''
                    ? escapeHtml(
                        safeDeepGet(
                          row,
                          ['notification_open', 0, 'detail', 'content'],
                          ''
                        )
                      )
                    : escapeHtml(safeDeepGet(row, 'desc', ''))
                }
                style={{
                  fontSize: 13,
                  border: 'none',
                  outline: 'none',
                  resize: 'none',
                }}
              ></textarea>
            )}
          </>
        ),
      },
      {
        dataField: 'handling',
        text: 'Handling',
        formatter: (cellContent, row) => (
          <>{escapeHtml(safeDeepGet(row, 'handling', ''))}</>
        ),
      },
      {
        dataField: 'action',
        isDummyField: true,
        editable: false,
        text: 'Action',
        formatter: (cellContent, row) => (
          <>
            {row.type === 'complaint' ? (
              <div className="d-flex gap-3">
                <Button
                  className="btn btn-primary"
                  id="edittooltip"
                  // onClick={() => handleCompleteClick(row)}
                >
                  Solve
                </Button>
              </div>
            ) : (
              <p>Automatic Solve</p>
            )}
          </>
        ),
      },
    ];
  }
}

export function url_Calibration(row) {
  if (
    APPLICATION_ID == '1000000209' ||
    APPLICATION_ID == '1000000232' ||
    APPLICATION_ID == '1000000226' ||
    APPLICATION_ID == '1000000260'
  ) {
    return `/business-point/options/${safeDeepGet(
      row,
      'device_id',
      'default'
    )}`;
  } else if (APPLICATION_ID == '1000000211' || APPLICATION_ID == '1000000226') {
    return `/business-point/options/${safeDeepGet(row, 'id', 'default')}`;
  } else {
    return `/business-point/options/${safeDeepGet(row, 'iddevice', 'default')}`;
  }
}

export function url_Account(row) {
  if (
    APPLICATION_ID == '1000000209' ||
    APPLICATION_ID == '1000000232' ||
    APPLICATION_ID == '1000000226' ||
    APPLICATION_ID == '1000000260'
  ) {
    return `/RFID/list/${safeDeepGet(row, 'device_id', 'default')}`;
  } else {
    return `/RFID/list/${safeDeepGet(row, 'id', 'default')}`;
  }
}

export function url_Planogram(row) {
  if (
    APPLICATION_ID == '1000000209' ||
    APPLICATION_ID == '1000000232' ||
    APPLICATION_ID == '1000000260'
  ) {
    return `/business-point/planogram/${row.type}/${row.device_id}`;
  } else if (APPLICATION_ID == '1000000226') {
    return `/business-point/planogram/${row.device_id}`;
  } else {
    return `/business-point/planogram/${safeDeepGet(row, 'id', 'default')}`;
  }
}

export function url_Alert(row) {
  if (
    APPLICATION_ID == '1000000209' ||
    APPLICATION_ID == '1000000232' ||
    APPLICATION_ID == '1000000226' ||
    APPLICATION_ID == '1000000260'
  ) {
    return `/business-point/alert/${safeDeepGet(row, 'device_id', 'default')}`;
  } else {
    return `/business-point/alert/${safeDeepGet(row, 'id', 'default')}`;
  }
}

export function url_Stock(row) {
  if (
    APPLICATION_ID == '1000000209' ||
    APPLICATION_ID == '1000000232' ||
    APPLICATION_ID == '1000000226' ||
    APPLICATION_ID == '1000000260'
  ) {
    return `/business-point/stock/${safeDeepGet(row, 'device_id', 'default')}`;
  } else {
    return `/business-point/stock/${safeDeepGet(row, 'id', 'default')}`;
  }
}

export function url_Info(row) {
  if (
    APPLICATION_ID == '1000000209' ||
    APPLICATION_ID == '1000000232' ||
    APPLICATION_ID == '1000000226' ||
    APPLICATION_ID == '1000000260'
  ) {
    return `/business-point/info/${safeDeepGet(row, 'device_id', 'default')}`;
  } else if (APPLICATION_ID == '1000000211') {
    return `/business-point/info/${safeDeepGet(row, 'id', 'default')}`;
  } else {
    return `/business-point/info/${safeDeepGet(row, 'iddevice', 'default')}`;
  }
}
export function url_options(row) {
  if (
    APPLICATION_ID == '1000000209' ||
    APPLICATION_ID == '1000000232' ||
    APPLICATION_ID == '1000000226' ||
    APPLICATION_ID == '1000000260'
  ) {
    return `/business-point/options/${safeDeepGet(
      row,
      'device_id',
      'default'
    )}`;
  } else if (APPLICATION_ID == '1000000211') {
    return `/business-point/options/${safeDeepGet(row, 'id', 'default')}`;
  } else {
    return `/business-point/options/${safeDeepGet(row, 'iddevice', 'default')}`;
  }
}
export function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : '';
    });
  });
  sheetData.unshift(header);
  return sheetData;
}
export function getFormatReport(list) {
  const dataSet = [];
  let trx_data = {};
  if (list.length > 0) {
    if (
      APPLICATION_ID == '1000000159' ||
      APPLICATION_ID == '1000000211' ||
      APPLICATION_ID == '1000000226'
    ) {
      let i = 1;
      for (const el in list) {
        let dispense = safeDeepGet(
          list[el],
          ['detail', 'dispense_status'],
          '-'
        );
        const transactionStatus = safeDeepGet(
          list[el],
          ['detail', 'transaction_status'],
          '-'
        );
        const firebaseTimestamp = list[el].time.firestore_timestamp;
        var transactionTime = firebaseTimestamp.toDate();

        let finalStatus = null;
        if (transactionStatus == 'settlement' && dispense == 'success') {
          finalStatus = 'Transaksi Sukses';
        } else if (
          transactionStatus == 'settlement' &&
          dispense == 'no_dispense'
        ) {
          var twoDaysLater = transactionTime.setDate(
            transactionTime.getDate() + 2
          );
          var now = new Date();
          if (now < twoDaysLater) {
            finalStatus = 'Gagal Dispense (Potential Refund)';
          } else {
            // more than 2 * 24 hours
            finalStatus = 'Transaksi Sukses';
          }
        } else if (
          transactionStatus == 'pending' ||
          transactionStatus == 'cancel'
        ) {
          finalStatus = 'Tidak Terjadi Transaksi';
        } else if (
          transactionStatus == 'refund' ||
          transactionStatus == 'refunded'
        ) {
          finalStatus = 'Refund';
        }
        const provider = safeDeepGet(list[el], ['payment', 'method'], '');
        let paymentIssuer = '';
        if (provider == '') {
          paymentIssuer = safeDeepGet(list[el], ['payment', 'method'], '');
        } else {
          if (provider == 'AIRPAY SHOPEE') {
            paymentIssuer = 'SHOPEEPAY';
          } else {
            paymentIssuer = provider;
          }
        }
        const amount = list[el].payment.amount;

        const Persentase = 7 / 1000;
        const mdr = amount * Persentase;
        const net = amount - mdr;

        const sn = list[el].sn;

        var trxId = '';

        if (paymentIssuer == 'RFID') {
          trxId = handleValidDate(list[el].time.firestore_timestamp);
        } else {
          trxId = safeDeepGet(
            list[el],
            ['payment', 'detail', 'transaction_id'],
            '-'
          );
        }
        var firebaseRefundTimestamp = safeDeepGet(
          list[el],
          ['detail', 'refund_time'],
          null
        );
        let refund = '';

        if (
          firebaseRefundTimestamp != null ||
          firebaseRefundTimestamp != undefined
        ) {
          const time = firebaseRefundTimestamp;

          var refundTime = handleValidRefundDate(Number(time));
          if (refundTime != null) {
            refund = refundTime;
          }
        }
        trx_data = {
          no: i++,
          date: handleValidDate(list[el].time.firestore_timestamp),
          business_point: safeDeepGet(list[el], 'name', '-'),
          sn: sn,
          column: safeDeepGet(list[el], ['product', 'column'], '-'),
          distributor: safeDeepGet(list[el], 'distributor', '-'),
          channel: safeDeepGet(list[el], 'channel', '-'),
          sku: safeDeepGet(list[el], 'nameProduct', '-'),
          total: amount,
          mdr: safeDeepGet(list[el], ['payment', 'fee', 'mdr_qris'], mdr),
          net:
            safeDeepGet(list[el], ['detail', 'transaction_status'], '-') ==
            'settlement'
              ? safeDeepGet(list[el], ['payment', 'nett'], net)
              : safeDeepGet(list[el], ['payment', 'amount'], 0),
          provider: paymentIssuer,
          issuer: safeDeepGet(list[el], ['payment', 'detail', 'issuer'], '-'),
          order_id: safeDeepGet(list[el], ['detail', 'order_id'], ''),
          trx_id: trxId,
          payment_status: safeDeepGet(
            list[el],
            ['detail', 'transaction_status'],
            '-'
          ),
          refundTime: refund,
          dispense_status: safeDeepGet(list[el], ['detail', 'dispense_status']),
          finalStatus: finalStatus,
        };
        dataSet.push(trx_data);
      }
    } else if (APPLICATION_ID == '1000000250') {
      for (const el in list) {
        trx_data = {
          order_id: safeDeepGet(list[el], ['detail', 'order_id'], '-'),
          business_point: safeDeepGet(list[el], 'name', '-'),
          date: handleValidDateBp(list[el].time.timestamp),
          sku: safeDeepGet(
            list[el],
            'nameProduct',
            safeDeepGet(list[el], 'sku', '-')
          ),
          column: safeDeepGet(list[el], ['product', 'column'], '-'),
          total: safeDeepGet(list[el], ['payment', 'amount'], 0),
          mdr:
            safeDeepGet(list[el], ['detail', 'transaction_status'], '-') ==
            'settlement'
              ? safeDeepGet(list[el], ['payment', 'fee', 'mdr_qris'], 0)
              : 0,
          sharing:
            safeDeepGet(list[el], ['detail', 'transaction_status'], '-') ==
            'settlement'
              ? safeDeepGet(
                  list[el],
                  ['payment', 'fee', 'platform_sharing_revenue'],
                  0
                )
              : 0,
          nett:
            safeDeepGet(list[el], ['detail', 'transaction_status'], '-') ==
            'settlement'
              ? safeDeepGet(list[el], ['payment', 'nett'], 0)
              : safeDeepGet(list[el], ['payment', 'amount'], 0),
          hpp: safeDeepGet(list[el], ['payment', 'hpp'], 0),
          share: safeDeepGet(list[el], ['payment', 'share'], 0),
          method: safeDeepGet(list[el], ['payment', 'method'], '-'),
          issuer: safeDeepGet(list[el], ['payment', 'detail', 'issuer'], '-'),

          payment_status: safeDeepGet(
            list[el],
            ['detail', 'transaction_status'],
            '-'
          ),
          dispense_status: safeDeepGet(
            list[el],
            ['detail', 'dispense_status'],
            '-'
          ),
        };
        dataSet.push(trx_data);
      }
    } else {
      for (const el in list) {
        trx_data = {
          order_id: safeDeepGet(list[el], ['detail', 'order_id'], '-'),
          business_point: safeDeepGet(list[el], 'name', '-'),
          date: handleValidDateBp(list[el].time.timestamp),
          sku: safeDeepGet(
            list[el],
            'nameProduct',
            safeDeepGet(list[el], 'sku', '-')
          ),
          column: safeDeepGet(list[el], ['product', 'column'], '-'),
          total: safeDeepGet(list[el], ['payment', 'amount'], 0),
          mdr:
            safeDeepGet(list[el], ['detail', 'transaction_status'], '-') ==
            'settlement'
              ? safeDeepGet(list[el], ['payment', 'fee', 'mdr_qris'], 0)
              : 0,
          sharing:
            safeDeepGet(list[el], ['detail', 'transaction_status'], '-') ==
            'settlement'
              ? safeDeepGet(
                  list[el],
                  ['payment', 'fee', 'platform_sharing_revenue'],
                  0
                )
              : 0,
          nett:
            safeDeepGet(list[el], ['detail', 'transaction_status'], '-') ==
            'settlement'
              ? safeDeepGet(list[el], ['payment', 'nett'], 0)
              : safeDeepGet(list[el], ['payment', 'amount'], 0),
          method: safeDeepGet(list[el], ['payment', 'method'], '-'),
          issuer: safeDeepGet(list[el], ['payment', 'detail', 'issuer'], '-'),

          payment_status: safeDeepGet(
            list[el],
            ['detail', 'transaction_status'],
            '-'
          ),
          dispense_status: safeDeepGet(
            list[el],
            ['detail', 'dispense_status'],
            '-'
          ),
        };
        dataSet.push(trx_data);
      }
    }
    return dataSet;
  }
}

export function handleExport(
  data,
  selectedMonthYear,
  businessPoints,
  dateRange
) {
  let headerAdmin = [
    'No',
    'Time',
    'Business Point',
    'SN Milano',
    'Column',
    'Distributor',
    'Channel',
    'Product Name',
    'Price on Time (Include Promo)',
    'MDR',
    'Net Price',
    'Provider',
    'Order Id',
    'TRX ID',
    'Transaction',
    'Refund Time',
    'Dispense Status',
    'Final Status',
  ];
  let header = [
    'No',
    'Time',
    'Business Point',
    'SN Milano',
    'Column',
    'Distributor',
    'Channel',
    'Product Name',
    'Price on Time (Include Promo)',
    'MDR',
    'Net Price',
    'Provider',
    'Order Id',
    'TRX ID',
    'Refund Time',
    'Final Status',
  ];

  const obj = JSON.parse(localStorage.getItem('role'));
  let val = selectedMonthYear;
  let bp_name = businessPoints.value
    ? safeDeepGet(data[0], 'distributor', '-')
    : businessPoints;
  let date = 'all';
  let month = '';
  let year = '';

  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const date1 = new Date(dateRange[0]).toLocaleDateString('en-GB', options);
  const date2 = new Date(dateRange[1]).toLocaleDateString('en-GB', options);
  let A3 = 'Dari ' + date1 + ' Sampai ' + date2;

  let A2 = '';
  if (val == null) {
    month = 'all';
    year = '';
    A2 = 'TRX REPORT';
  } else {
    date = new Date(
      parseInt(val.split('-')[0]),
      parseInt(val.split('-')[1]) - 1,
      1
    );
    month = date.toLocaleString('default', { month: 'long' });
    year = parseInt(val.split('-')[0]);
    A2 = 'TRX REPORT ' + month + ' ' + year;
  }
  XlsxPopulate.fromBlankAsync().then(async workbook => {
    const sheet1 = workbook.sheet(0);
    let sheetData;
    if (obj == null) {
      sheetData = getSheetData(data, headerAdmin);
    } else {
      if (obj.role == 'Admin' || obj.role == null) {
        sheetData = getSheetData(data, headerAdmin);
      } else {
        sheetData = getSheetData(data, header);
      }
    }

    const totalColumns = sheetData[0].length;
    sheet1.cell('A15').value(sheetData);
    sheet1.cell('A1').value(bp_name);
    sheet1.cell('A2').value(A2);
    sheet1.cell('A3').value(A3);
    // CUP Sales
    sheet1.cell('A4').value('TOTAL CUP SALES');
    sheet1
      .cell('A4')
      .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });

    if (obj == null) {
      sheet1
        .cell('B4')
        .formula(
          '=COUNTIFS($R:$R,"Transaksi Sukses")+COUNTIFS($R:$R,"Refund")'
        );
    } else {
      if (obj.role == 'Admin' || obj.role == null) {
        sheet1
          .cell('B4')
          .formula(
            '=COUNTIFS($R:$R,"Transaksi Sukses")+COUNTIFS($R:$R,"Refund")'
          );
      } else {
        sheet1
          .cell('B4')
          .formula(
            '=COUNTIFS($P:$P,"Transaksi Sukses")+COUNTIFS($P:$P,"Refund")'
          );
      }
    }
    sheet1
      .cell('B4')
      .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });

    // Total Sales
    sheet1.cell('A5').value('TOTAL SALES (VALUE)');
    sheet1
      .cell('A5')
      .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
    if (obj == null) {
      sheet1
        .cell('B5')
        .formula(
          '=SUMIF($R:$R,"Transaksi Sukses",$I:$I)+SUMIF($R:$R,"Refund",$I:$I)'
        );
    } else {
      if (obj.role == 'Admin' || obj.role == null) {
        sheet1
          .cell('B5')
          .formula(
            '=SUMIF($R:$R,"Transaksi Sukses",$I:$I)+SUMIF($R:$R,"Refund",$I:$I)'
          );
      } else {
        sheet1
          .cell('B5')
          .formula(
            '=SUMIF($P:$P,"Transaksi Sukses",$I:$I)+SUMIF($P:$P,"Refund",$I:$I)'
          );
        sheet1
          .cell('B4')
          .formula(
            '=COUNTIFS($P:$P,"Transaksi Sukses")+COUNTIFS($P:$P,"Refund")'
          );
      }
    }
    sheet1.cell('B5').style({
      fontColor: '2172d7',
      fontSize: '11',
      fontFamily: 'Calibri',
      numberFormat: 'Rp #,###.0; -',
    });

    //Refund
    sheet1.cell('A6').value('REFUND');
    sheet1
      .cell('A6')
      .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
    if (obj == null) {
      sheet1.cell('B6').formula('=SUMIF($R:$R,"Refund",$I:$I)');
    } else {
      if (obj.role == 'Admin' || obj.role == null) {
        sheet1.cell('B6').formula('=SUMIF($R:$R,"Refund",$I:$I)');
      } else {
        sheet1.cell('B6').formula('=SUMIF($P:$P,"Refund",$I:$I)');
      }
    }
    sheet1.cell('B6').style({
      fontColor: '2172d7',
      fontSize: '11',
      fontFamily: 'Calibri',
      numberFormat: 'Rp #,###.0; -',
    });

    // Total Sales (Actual)
    sheet1.cell('A7').value('TOTAL SALES (ACTUAL)');
    sheet1.cell('B7').formula('B5-B6');
    sheet1.cell('B7').style('numberFormat', 'Rp #,###.0; -');

    // MDR
    sheet1.cell('A8').value('MDR');
    sheet1
      .cell('A8')
      .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
    if (obj == null) {
      sheet1.cell('B8').formula('=SUMIFS($J:$J,$R:$R,"Transaksi Sukses")');
    } else {
      if (obj.role == 'Admin' || obj.role == null) {
        sheet1.cell('B8').formula('=SUMIFS($J:$J,$R:$R,"Transaksi Sukses")');
      } else {
        sheet1.cell('B8').formula('=SUMIFS($J:$J,$P:$P,"Transaksi Sukses")');
      }
    }
    sheet1.cell('B8').style({
      fontColor: '2172d7',
      fontSize: '11',
      fontFamily: 'Calibri',
      numberFormat: 'Rp #,###.0; -',
    });

    sheet1.cell('C8').value('0.7%');

    //Net Tranfer
    sheet1.cell('A11').value('Net Transfer to Distributor');
    sheet1.cell('B11').formula('=B7-B8');
    sheet1.cell('B11').style('numberFormat', 'Rp #,###.0; -');

    sheet1.cell('A13').value('PPn');
    sheet1.cell('B13').formula('=B11*11%/111%');
    sheet1.cell('B13').style('numberFormat', 'Rp #,###.0; -');
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style('bold', true);
    sheet1.range('A15:' + endColumn + '15').style('fill', '2172d7');
    // range.style('border', true);

    var filename;
    var tenant = PROJECT_HANDLE;
    if (PROJECT_HANDLE === 'Fuji') {
      tenant = 'VendkiosK';
    } else if (APPLICATION_ID == '1000000159') {
      tenant = 'Milano';
    }
    if (obj == null) {
      filename = tenant + '_report_Report_Transaction_Ho_' + bp_name + '.xlsx';
    } else {
      if (obj.role == 'Admin' || obj.role == null) {
        filename =
          tenant + '_report_Report_Transaction_Ho_' + bp_name + '.xlsx';
      } else {
        filename =
          tenant + '_report_Report_Transaction_Dist_' + bp_name + '.xlsx';
      }
    }

    return workbook.outputAsync().then(res => {
      saveAs(res, filename);
    });
  });
}

export function formatedNumber(stringNumber) {
  var split = stringNumber.split('.');
  var sisa = split[0].length % 3;
  let rupiah = split[0].substr(0, sisa);
  var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    var separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
  return rupiah;
}

const ButtonDownload = ({
  list,
  report_list,
  onClick,
  report_filename,
  selectedMonthYear,
  businessPoints,
  dateRange,
  isLoading,
}) => {
  if (APPLICATION_ID == '1000000159') {
    return (
      <div className="clearfix mt-4 mt-lg-0">
        <Button
          type="button"
          className="btn btn-nestle w-sm"
          disabled={list.length == 0 ? true : false}
          onClick={() =>
            handleExport(
              report_list,
              selectedMonthYear,
              businessPoints,
              dateRange
            )
          }
        >
          <i className="mdi mdi-download font-size-12 m-auto"></i> Download
          Excel
        </Button>
      </div>
    );
  } else if (APPLICATION_ID == '1000000211' || APPLICATION_ID == '1000000226') {
    return (
      <div className="clearfix mt-4 mt-lg-0">
        <Button
          type="button"
          className="btn btn-franke w-sm"
          disabled={list.length == 0 ? true : false}
          onClick={() =>
            handleExport(
              report_list,
              selectedMonthYear,
              businessPoints,
              dateRange
            )
          }
        >
          <i className="mdi mdi-download font-size-12 m-auto"></i> Download
          Excel
        </Button>
      </div>
    );
  } else {
    return (
      <div className="clearfix mt-4 mt-lg-0">
        <Button
          type="button"
          color="primary"
          className="btn btn-primary w-sm"
          disabled={list.length == 0 || isLoading ? true : false}
          onClick={onClick}
        >
          <i className="mdi mdi-download font-size-12 m-auto"></i> Download
          Excel
        </Button>
      </div>
    );
  }
};
export default ButtonDownload;
